import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const messages = {
  en: {
    message: {
      ParkingRedemption: "Parking Redemption",
      Redemption: "Redemption",
      Search: "Search",
      ScanQRCode: "Scan QRCode",
      PleaseInput: "Please Input",
      InputPlaceholder: "License No./Ticket No./Log Id.",
      ParkingInformation: "Parking Information",
      TicketNo: "Ticket No.",
      DateTime: "Date",
      DateTimeIn: "DateTimeIn",
      TimeIn: "Time In",
      Duration: "Duration",
      exempt: "Exempt",
      summaryDuration: "Summary Duration",
      CarLicense: "Car License",
      VehicleName: "Vehicle Name",
      RateCode: "RateCode",
      RateDetail: "Rate Detail",
      Total: "Total",
      SpendingValue: "Spending Value",
      POSValue: "POS Value",
      Scanner: "Scanner",
      ClearRate: "Clear Rate Detail",
      Remark: "Remark",
      EStamp: "E-Stamp",
      Cancel: "Cancel",
      RedemptionSuccess: "Redemption Success !",
      Back: "Back",
      Submit: "Submit",
      TotalAmount: "Total",
      Add: "Add",
      Baht: "Baht",
      CouponValue: "Coupon",
      Hour: "Hour",
      TotalCoupon: "Total Coupon",
      POSValueOrCoupon: "POS/Coupon",
      UsageTiger: "Usage Tiger Retail Shop",
      UsageCoupon: "Rabbit Rewards Coupon",
      NormalServiceRate: "Rate Detail",
      PromotionDiscount: "Promotion Discount",
      ReceiptFromPurchase: "Receipt From Purchase",
      TotalPromotionDiscount: "Total Promotion Discount",
      RateDetailLang: "ENG",
      Close: "Close",
      VehicleType: "VehicleType",
      Next: "Next",
      Car: "Car",
      Motorcycle: "Motorcycle",
    },
  },
  th: {
    message: {
      ParkingRedemption: "ส่วนลดค่าบริการที่จอดรถ",
      Redemption: "ประทับตราส่วนลดค่าจอดรถ",
      Search: "ค้นหา",
      ScanQRCode: "สแกนคิวอาร์โค้ด",
      PleaseInput: "กรุณากรอก",
      InputPlaceholder: "ทะเบียนรถ/เลขบัตรจอดรถ/รหัสรายการ",
      ParkingInformation: "ข้อมูลที่จอดรถ",
      TicketNo: "เลขบัตรจอดรถ",
      DateTime: "วันที่",
      DateTimeIn: "วันที่เข้า",
      TimeIn: "เวลาเข้า",
      Duration: "ระยะเวลาจอดรถ",
      exempt: "ได้รับยกเว้น",
      summaryDuration: "เวลาสุทธิ",
      CarLicense: "ทะเบียนรถ",
      VehicleName: "ประเภทยานพาหนะ",
      RateCode: "รหัสตราประทับ",
      RateDetail: "รายละเอียดเงื่อนไข",
      Total: "ค่าจอดรถ",
      SpendingValue: "ยอดค่าใช้จ่าย",
      POSValue: "ยอดใบเสร็จ POS",
      Scanner: "เครื่องสแกน",
      ClearRate: "เคลียร์เงื่อนไข",
      Remark: "หมายเหตุ",
      EStamp: "ประทับตรา",
      Redeem: "ไถ่ถอน",
      Cancel: "ยกเลิก",
      RedemptionSuccess: "ประทับตราส่วนลดสำเร็จ",
      Back: "ย้อนกลับ",
      Submit: "ตกลง",
      TotalAmount: "รวมทั้งสิ้น",
      Add: "เพิ่มข้อมูล",
      Baht: "บาท",
      CouponValue: "ยอดคูปอง",
      Hour: "ชั่วโมง",
      TotalCoupon: "รวมคูปอง",
      POSValueOrCoupon: "ยอดใบเสร็จ POS/คูปอง",
      UsageTiger: "ใช้บริการร้านค้า Tiger",
      UsageCoupon: "คูปอง Rabbit Rewards",
      NormalServiceRate: "อัตราค่าบริการปกติ",
      PromotionDiscount: "ส่วนลดจากโปรโมชัน",
      ReceiptFromPurchase: "ใบเสร็จจากการซื้อสินค้า",
      TotalPromotionDiscount: "รวมส่วนลดโปรโมชัน",
      RateDetailLang: "TH",
      Close: "ปิด",
      VehicleType: "กรุณาเลือกประเภทรถ",
      Next: "ต่อไป",
      Car: "รถยนต์",
      Motorcycle: "รถจักรยานยนต์",
    },
  },
};
const i18n = new VueI18n({
  locale: "th",
  messages,
});

Vue.filter("myLocale", function(value) {
  return i18n.t(value);
});

export default i18n;
