<template>
  <div>
    <v-card style="display: contents;">
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
            cursor: default;
            margin-top: -1rem;
          "
        ></div>
        <v-card-text>
          <div>
            <v-row
              cols="12"
              xs="12"
              sm="12"
              md="12"
              justify="center"
              :style="$vuetify.breakpoint.smAndDown ? { marginTop: '10%' } : $vuetify.breakpoint.mdAndDown ? { marginTop: '10%' } : { marginTop: '3%' }"

            >
              <v-col cols="12" md="12" align="center" style="font-size: 22px; color: #14223c;" >
                <p>Parking Redemption</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-tabs>
                  <v-tab @click="ChangeTab('Search')">Search</v-tab>
                  <v-tab @click="ChangeTab('QR')">Scan QRCode</v-tab>
                  <v-tabs-slider :color="ColorTab"></v-tabs-slider>
                  <v-tab-item>
                    <v-card flat :style="$vuetify.breakpoint.smAndDown ? { marginTop: '20%' } : { marginTop: '0%' }">
                      <v-card-text>
                        <v-row
                          cols="12"
                          md="12"
                          justify="center"
                          
                        >
                          <v-col cols="12" md="6">
                            <p class="TitleCSS">Please Input</p>
                            <v-text-field
                              placeholder="License Number./Ticket Number./Log Id."
                              outlined
                              v-model="SearchInput"
                              @keyup.enter="SearchParking"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                          cols="12"
                          xs="12"
                          sm="12"
                          md="12"
                          justify="center"
                        >
                          <v-col cols="12" md="4" align="center">
                            <v-btn
                              height="40"
                              width="150"
                              outlined
                              color="indigo"
                              @click="SearchParking()"
                            >
                              Search
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <v-row
                          cols="12"
                          md="12"
                          justify="center"
                          :style="$vuetify.breakpoint.smAndDown ? { marginTop: '20%' } : { marginTop: '0%' }"
                        >
                          <div v-if="Status">
                            <StreamBarcodeReader
                              ref="scanner"
                              style="height: auto"
                              @decode="onDecode"
                            ></StreamBarcodeReader>
                          </div>
                        </v-row>
                        <!-- <v-row cols="12">
                          <v-col cols="12" md="4"></v-col>
                          <v-col cols="12" md="4" align="center">
                            <div v-if="Status">
                              <StreamBarcodeReader
                                ref="scanner"
                                style="height: auto"
                                @decode="onDecode"
                              ></StreamBarcodeReader>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4"></v-col>
                        </v-row> -->
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="0.01">
      <v-card>
        <v-card-text>
          <v-row>
            <LoadingComponent class="centered"></LoadingComponent>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <AlertComponent
      :flagAlert="AlertDialog"
      :typeAlert="TypeAlert"
      :mesAlert="MessageAlert"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import { StreamBarcodeReader } from "vue-barcode-reader";
import enurl from "@/api/environment";
import AlertComponent from "@/components/Alert";
import LoadingComponent from "@/components/Loading";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  name: "Main",
  components: {
    AlertComponent,
    StreamBarcodeReader,
    LoadingComponent,
  },
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      model: "tab-1",
      SearchInput: "",
      QRCodeData: "",
      id: null,
      destroy: false,
      Status: false,
      response: "",
      ColorTab: "#126496",
      ColorText: "#126496",
      ColorText2: "",     
    };
  },
  computed: {},
  watch: {},

  methods: {
    onDecode(temp) {
      console.log(temp);
      this.QRCodeData = temp;
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.QRCodeData === temp) {
          this.QRCodeData = "";
        }
      }, 5000);
      this.SearchParkingByQRCode(temp);
    },

    ChangeTab(value) {
      let self = this;
      if (value == "Search") {
        self.$refs.scanner.codeReader.stream
          .getTracks()
          .forEach(function (track) {
            track.stop();
          });
        self.ColorTab = "#126496";
        self.ColorText = "#126496";
        self.Status = false;
      } else {
        self.ColorTab = "orange";
        self.Status = true;
      }
    },

    CloseTab() {
      window.close();
    },

    ClearDataAlert() {
      let self = this;
      self.AlertDialog = false;
      self.TypeAlert = "";
      self.MessageAlert = "";
    },

    SearchParkingByQRCode(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        LogCarparkID: data,
        LostCard: false,
      };
      axios
        .post(`${self.url}Redemption/GetParkingDetailByQRCode`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.response = response.data.data;
            self.LoadingDialog = false;
            self.$router.push({
              name: "ParkingInformation",
              params: { response: self.response, LogID: self.LogCarparkID },
            });
          } else {
            self.LoadingDialog = false;
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error,
            });
            self.LoadingDialog = false;
        });
    },

    SearchParking() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        search: self.SearchInput,
        LostCard: false,
      };
      axios
        .post(`${self.url}Redemption/GetParkingDetail`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.response = response.data.data;
            self.LoadingDialog = false;
            self.$router.push({
              name: "ParkingInformation",
              params: { response: self.response, LogID: self.LogCarparkID },
            });
          } else {
            self.LoadingDialog = false;
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error,
            });
        });
    },
  },

  async mounted() {},
};
</script>
<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.centered-input-header >>> input {
  text-align: center;
  color: black;
}
.TitleCSS {
  font-size: 18px;
  font-weight: 300;
  color: black;
}
@font-face {
  font-family: "English";
  src: local("English"), url(../assets/Font/Kanit-Regular.ttf);
}
* >>> .v-data-table-header {
  background-color: #333333 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
