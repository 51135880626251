<template>
  <v-footer
    color="#1D2939"
    style="margin-top: auto"
    class="white--text text-caption"
  >
    <v-row no-gutters>
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" align="center">
          <div class="mt-2 inline-container">
            <v-img
              class="inline-item"
              src="@/assets/logofs_icon.svg"
              width="90"
            ></v-img>
            <span class="inline-item" style="font-size: small; margin-bottom: 5px; color: white; padding-left: 15px;"
              >Copyright © 2024 Forward System Co. Ltd.</span
            >
          </div>
        </v-col>
      </v-row>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style>
.inline-container {
  text-align: center;
}

.inline-item {
  display: inline-block;
  vertical-align: middle;
}

.inline-container span {
  font-size: small;
  margin-bottom: 5px;
  color: white;
}
</style>
